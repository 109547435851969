import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import ContentCard from '../components/Layout/ContentCard'
import SEO from "../components/seo"

export const query = graphql`
  query {
  allNodeRepair(sort: {fields: created, order: DESC}) {
    edges {
      node {
        id
        drupal_internal__nid
        path {
          alias
        }
        title
        field_teaser
        relationships {
          featuredImage: field_image {
            localFile {
              childImageSharp {
                fixed(width: 100, height: 100, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 150, maxHeight: 150, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
}`

const RepairsPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Bicycle Repairs" />
    <section className="container mx-auto px-8">
      <div className="page-title">Bicycle Repairs</div>
      {data.allNodeRepair.edges.map(repair => (
      <>
        <ContentCard data={repair.node} location={location} key={repair.node.id} />
      </>
      ))}
    </section>
  </Layout>
)

RepairsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default RepairsPage
